$('#header .js-eventClick-open-child').click(function(){
  if ($('#body').hasClass('is-active-menu')){
    $(this).toggleClass('is-open');
    $(this).siblings().removeClass('is-open');
  }
});
$(document).keyup(function(e) {
  if ($('#body').hasClass('is-active-menu')) {
    if (e.keyCode === 27) { $('#js-header__menu-mobile').click();}
  }
});
$('#js-body__header__menu-mobile').click(function(){
  $('#js-header__menu-mobile').click();
});
$('#js-header__menu-mobile').click(function(){
  $('#body').toggleClass('is-active-menu');
  $(this).toggleClass('is-active');
  $('.header__layer').toggleClass('is-active');
  if (!$('.header__layer').hasClass('is-active')) {
    $('.header__layer').addClass('no-active');
  } else {
    $('.header__layer').removeClass('no-active');
  }
});









// When the user scrolls the page, execute myFunction
window.onscroll = function() {myFunction()};

// Get the header
var header = document.getElementById("header");

// Get the offset position of the navbar
var sticky = header.offsetTop;

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
  //if (window.pageYOffset > sticky && window.innerWidth > 1120) {
    if (window.pageYOffset > 200) {
    // document.getElementById("main").style.paddingTop = header.clientHeight + ".px";
    header.classList.add("is-active-fixed");
  } else {
    // document.getElementById("main").style.paddingTop = 0;
    header.classList.remove("is-active-fixed");
  }
}

