// Vendor  node_modules jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

require('@fancyapps/fancybox');

require('material-scrolltop');
$('body').materialScrollTop();

var Flickity = require('flickity');
require('flickity-fade');
require('flickity-imagesloaded');


// Scripts
require('./modules/scripts');



	









